import Style from './style';
import React from 'react';
import { formateTimeByTimeZone } from 'utils/datetime.js';
import { useIntl } from 'react-intl';

interface Statistics {
  fansNum: Number;
  joinUserNum: Number;
  lastReplyAt: Number;
  lastReplyUserNo: String;
  laseReplyHead: String;
  replyNum: Number;
  shareNum: Number;
  visitNum: Number;
  replyRanks: Array<Object>;
}
const BlogStatistics = (props) => {
  const blogStatistic: Statistics = props.blogStatistic;
  const intl = useIntl();
  return (
    <Style>
      <div>
        <div className='reply-data-container'>
          <div className='reply-data-line-one'>
            <div className='reply-data-item reply-data-item-first'>
              <div className='reply-data-title'>
                {intl.formatMessage({
                  defaultMessage: '最后回复时间',
                  id: '5a2b0f7a45704952a331d8a7cd60476e',
                })}
              </div>

              <div className='reply-data-value'>
                {blogStatistic.lastReplyAt ? formateTimeByTimeZone(new Date(blogStatistic.lastReplyAt)) : '-'}
              </div>
            </div>
            <div className='reply-data-item'>
              <div className='reply-data-title'>
                {intl.formatMessage({
                  defaultMessage: '最后回复人',
                  id: '409b07af0ea44901b1ed28347fbed1c1',
                })}
              </div>
              <div className='reply-data-value last-reply-user'>
                {blogStatistic.lastReplyUserNo ? (
                  <>
                    <svg width={20} height={20}>
                      <use xlinkHref='#iconic-touxiang'></use>
                    </svg>
                    <span>{blogStatistic.lastReplyUserNo}</span>
                  </>
                ) : (
                  '-'
                )}
              </div>
            </div>
          </div>
          <div className='reply-data-item follow-container'>
            <div className='reply-data-title'>
              {intl.formatMessage({
                defaultMessage: '跟帖排行榜',
                id: '29a40b431b7c4b7999d892b66aa2f264',
              })}
            </div>
            <div className='reply-top-container'>
              {blogStatistic.replyRanks?.map((item) => {
                return (
                  <div className='reply-top-item' key={item?.headIcon}>
                    {item?.headIcon && <img src={item.headIcon}></img>}
                    {!item?.headIcon && (
                      <svg width={32} height={32}>
                        <use xlinkHref='#iconic-touxiang'></use>
                      </svg>
                    )}

                    <div className='reply-num'>{item?.replyNum}</div>
                  </div>
                );
              })}
              {blogStatistic.replyRanks?.length === 0 && '-'}
            </div>
          </div>
        </div>

        <div className='blog-data-container'>
          <div className='blog-data-item-line-one'>
            <div className='blog-data-item'>
              <div className='blog-data-value'>{blogStatistic.replyNum}</div>
              <div className='blog-data-title'>
                {intl.formatMessage({
                  defaultMessage: '总回复数',
                  id: 'd45511f10088475f8d7ffbb393de8c47',
                })}
              </div>
            </div>
            <div className='blog-data-item'>
              <div className='blog-data-value'>{blogStatistic.visitNum}</div>
              <div className='blog-data-title'>
                {intl.formatMessage({
                  defaultMessage: '总浏览量',
                  id: 'dashboard.data_overview.total_views',
                })}
              </div>
            </div>
            <div className='blog-data-item'>
              <div className='blog-data-value'>{blogStatistic.joinUserNum}</div>
              <div className='blog-data-title'>
                {intl.formatMessage({
                  defaultMessage: '参与用户',
                  id: 'e97c251cb89749a2b07c31b5111b5e6c',
                })}
              </div>
            </div>
          </div>
          <div className='blog-data-item-line-two'>
            <div className='blog-data-item'>
              <div className='blog-data-value'>{blogStatistic.fansNum}</div>
              <div className='blog-data-title'>
                {intl.formatMessage({ defaultMessage: '喜欢', id: '96b1a80ef4594041b066fcc104c0c1e4' })}
              </div>
            </div>
            <div className='blog-data-item'>
              <div className='blog-data-value'>{blogStatistic.shareNum}</div>
              <div className='blog-data-title'>{intl.formatMessage({ defaultMessage: '分享', id: 'share' })}</div>
            </div>
            <div className='empty-container'></div>
          </div>
        </div>
      </div>
    </Style>
  );
};

export default BlogStatistics;
