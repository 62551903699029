import Style from './style';
import React, { useEffect, useState } from 'react';
import shopApi from 'api/shop';
import { useIntl } from 'react-intl';
import onBlogReplyEditDialog from '../blog-reply-edit';
import { formateTimeByTimeZone } from 'utils/datetime.js';
import { closeDialog, Notify } from 'zent';

const BlogCommentItem = (props) => {
  const {
    blogId,
    rootId,
    parentId,
    shopLogoUrl,
    commentId,
    userRelateInfo,
    status,
    onCommentRefresh,
    onStatisticsRefresh,
    sourceType,
    parentCreatorNo,
    onCheckIsLogin,
    avatar,
  } = props;
  const logoUrl = avatar || shopLogoUrl;
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const COMMENT_STATUS = {
    delete: 'delete',
    visible: 'visible',
  };

  const SOURCE_TYPE = {
    admin: 'admin',
  };

  // 提交评论
  const onSubmitComment = (parentId, content) => {
    const params = {
      blogId,
      content,
      parentId,
      rootId,
    };
    shopApi.publishBlogCmt(params).then(() => {
      Notify.success(intl.formatMessage({ defaultMessage: '评论成功', id: '349356ce58d641b18984511f6a1c3f75' }));
      onCommentRefresh();
      onStatisticsRefresh();
      closeDialog('blog-reply-dialog');
    });
  };

  // 提交评论的回调
  const onCallbackSubmitComment = (parentId) => {
    return (content) => {
      onSubmitComment(parentId, content);
    };
  };

  // 点赞评论或者取消点赞
  const onToggleFansComment = (isFans, commentId) => {
    if (loading || !onCheckIsLogin()) return;
    setLoading(true);
    const apiName = isFans ? 'cancelFansBlogCmt' : 'fansBlogCmt';
    const toastTips = isFans
      ? intl.formatMessage({ defaultMessage: '取消成功', id: '36ffbcb3d53f43c99a1e78bb1ff80c08' })
      : intl.formatMessage({ defaultMessage: '点赞成功', id: 'e0f458ba364f47ffb15569f6115d4ec7' });
    shopApi[apiName]({ commentId, blogId }).then(() => {
      Notify.success(toastTips);
      onCommentRefresh();
      onStatisticsRefresh();
    });
  };

  // 打开评论弹窗
  const onOpenCommentDialog = (title, intl, callback) => {
    if (!onCheckIsLogin()) return;
    onBlogReplyEditDialog(title, intl, callback);
  };

  // 通过hash滚动
  const onHashScroll = (id) => {
    location.hash = id;
    setTimeout(() => {
      location.hash = 'init_hash_empty';
    }, 500);
  };

  useEffect(() => {
    setLoading(false);
  }, [userRelateInfo]);

  return (
    <Style id={commentId}>
      <div className='main-container'>
        <div className='head-img-container'>
          {sourceType === SOURCE_TYPE.admin &&
            (logoUrl ? (
              <img src={logoUrl} />
            ) : (
              <svg width={40} height={40}>
                <use xlinkHref='#iconic-bokemoren' />
              </svg>
            ))}
          {sourceType !== SOURCE_TYPE.admin && (
            <svg width={40} height={40} aria-hidden='true'>
              <use xlinkHref='#iconic-touxiang' />
            </svg>
          )}
        </div>
        <div className='content-container'>
          <div className='comment-action-container'>
            <div>
              <div className='user-name-container'>
                <span className='name'>
                  {props.creatorNo ||
                    intl.formatMessage({
                      defaultMessage: '匿名',
                      id: 'f203dcd1290446ed8fb683882a45874f',
                    })}
                </span>
                {sourceType === SOURCE_TYPE.admin && (
                  <span className='admin-symbol'>
                    {intl.formatMessage({
                      defaultMessage: '官方',
                      id: '5f2795dfbc2040ce848f5ca2e5f2b6e9',
                    })}
                  </span>
                )}
              </div>
              <div className='create-time-container'>{formateTimeByTimeZone(new Date(props.createdAt))}</div>
            </div>
            <div className='back-container'>
              {commentId !== rootId && (
                <>
                  <svg
                    width={17}
                    height={17}
                    onClick={() => {
                      onHashScroll(`${parentId}-${commentId}`);
                    }}
                  >
                    <use xlinkHref='#iconic-fanhui' />
                  </svg>
                  <span>
                    {parentCreatorNo ||
                      intl.formatMessage({
                        defaultMessage: '官方客服',
                        id: '81058b291f23454592eff88f99e0a7e8',
                      })}
                  </span>
                </>
              )}
            </div>
          </div>
          <div className='comment-content-container'>
            {status === COMMENT_STATUS.visible ? (
              props.content
            ) : (
              <span className='delete-text'>
                {intl.formatMessage({
                  defaultMessage: '该评论已被删除',
                  id: '755d589d05504e0facdad16e3d7bbb64',
                })}
              </span>
            )}
          </div>
          <div className='comment-data-container'>
            <div
              className='comment-data-item-container comment-data-item-first'
              onClick={() => {
                onToggleFansComment(userRelateInfo?.isFans, commentId);
              }}
            >
              <svg width={13} height={13} fill='#333333'>
                <use xlinkHref={userRelateInfo?.isFans ? '#iconic-dianzan2' : '#iconic-dianzan1'} />
              </svg>
              <span>{props.fansNum}</span>
            </div>
            <div
              className='comment-data-item-container'
              onClick={() => {
                onOpenCommentDialog(
                  intl.formatMessage(
                    { defaultMessage: '回复', id: 'c6986c8d40a84860b23db01d04350591' },
                    {
                      name:
                        props.creatorNo ||
                        intl.formatMessage({
                          defaultMessage: '官方',
                          id: '5f2795dfbc2040ce848f5ca2e5f2b6e9',
                        }),
                    },
                  ),
                  intl,
                  onCallbackSubmitComment(commentId),
                );
              }}
            >
              <svg width={16} height={16} fill='#333333'>
                <use xlinkHref='#iconic-pingjia' />
              </svg>
              <span>{props.replyNum}</span>
            </div>
          </div>
          {props.replyItems?.length > 0 && (
            <div className='two-level-comment-container'>
              {props.replyItems?.map((item) => {
                return (
                  <div className='two-level-comment' key={item?.userName} id={`${item?.parentId}-${item?.commentId}`}>
                    <div className='tow-level-img-container'>
                      {item?.sourceType === SOURCE_TYPE.admin &&
                        (item?.avatar || shopLogoUrl ? (
                          <img src={item?.avatar || shopLogoUrl} />
                        ) : (
                          <svg width={32} height={32}>
                            <use xlinkHref='#iconic-bokemoren' />
                          </svg>
                        ))}
                      {item?.sourceType !== SOURCE_TYPE.admin && (
                        <svg width={32} height={32} aria-hidden='true'>
                          <use xlinkHref='#iconic-touxiang' />
                        </svg>
                      )}
                    </div>
                    <div className='two-level-comment-content'>
                      <div className='user-name-container'>
                        <div>
                          <div className='two-level-user-name'>
                            <span className='name'>
                              {item.creatorNo ||
                                intl.formatMessage({
                                  defaultMessage: '匿名',
                                  id: 'f203dcd1290446ed8fb683882a45874f',
                                })}
                            </span>

                            {item?.sourceType === SOURCE_TYPE.admin && (
                              <span className='admin-symbol'>
                                {intl.formatMessage({
                                  defaultMessage: '官方',
                                  id: '5f2795dfbc2040ce848f5ca2e5f2b6e9',
                                })}
                              </span>
                            )}
                          </div>
                          <div className='two-level-create-time'>{formateTimeByTimeZone(new Date(item.createdAt))}</div>
                        </div>
                        <div
                          className='drop-down-icon-container'
                          onClick={() => {
                            onHashScroll(item.commentId);
                          }}
                        >
                          <svg width={16} height={16}>
                            <use xlinkHref='#iconic-xiaji' />
                          </svg>
                        </div>
                      </div>
                      <div className='two-level-content'>
                        {item?.status === COMMENT_STATUS.visible ? (
                          item.content
                        ) : (
                          <span className='delete-text'>
                            {intl.formatMessage({
                              defaultMessage: '该评论已被删除',
                              id: '755d589d05504e0facdad16e3d7bbb64',
                            })}
                          </span>
                        )}
                      </div>
                      <div className='comment-data-container'>
                        <div
                          className='comment-data-item-container comment-data-item-first'
                          onClick={() => {
                            onToggleFansComment(item?.userRelateInfo?.isFans, item?.commentId);
                          }}
                        >
                          <svg width={13} height={13} fill='#333333'>
                            <use xlinkHref={item?.userRelateInfo?.isFans ? '#iconic-dianzan2' : '#iconic-dianzan1'} />
                          </svg>
                          <span>{item.fansNum}</span>
                        </div>
                        <div
                          className='comment-data-item-container'
                          onClick={() => {
                            onOpenCommentDialog(
                              `回复 ${
                                item.creatorNo ||
                                intl.formatMessage({
                                  defaultMessage: '匿名',
                                  id: 'f203dcd1290446ed8fb683882a45874f',
                                })
                              }`,
                              intl,
                              onCallbackSubmitComment(item?.commentId),
                            );
                          }}
                        >
                          <svg width={16} height={16} fill='#333333'>
                            <use xlinkHref='#iconic-pingjia' />
                          </svg>
                          <span>{item.replyNum}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </Style>
  );
};

export default BlogCommentItem;
